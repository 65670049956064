<!-- eslint-disable vue/no-v-for-template-key-on-child -->
<!-- eslint-disable vue/valid-v-slot -->
<template>
  <LoadingSpinner :is-loaded="ready">
    <b-table
      v-if="ready"
      hover
      small
      sort-icon-left
      bordered
      :responsive="true"
      show-empty
      :fields="headerProcessed"
      :items="itemsProcessed"
      :sort-by="headerProcessed[0].key"
      :sort-desc="false"
      class="text-nowrap mt-3"
    >
      <template
        v-for="field of headerProcessed"
        v-slot:[`head(${field.key})`]="data"
      >
        <b-link
          v-if="data.field.type === 'problem'"
          :key="`header-${field.key}`"
          v-b-popover.hover.top.window="field.tooltip"
          :class="`header-${field.type}`"
          :to="{
            name: 'round',
            params: { roundId: roundId },
            hash: `\#uloha-${data.label}`,
          }"
        >
          {{ data.label }}
        </b-link>
        <div
          v-else
          :id="`header-${field.key}`"
          :key="`header-${field.key}`"
          v-b-popover.hover.top.window="field.tooltip"
        >
          {{ data.label }}
        </div>
      </template>
      <template v-slot:cell(change)="data">
        <PositionChange :change="data.item.change" />
      </template>
      <template v-slot:cell(enrollment.school)="data">
        <div v-b-popover.hover.top.window="data.item.enrollment.school_name">
          {{ shortSchoolName(data.item.enrollment.school) }}
        </div>
      </template>
      <template
        v-for="[pointsPath, countedPath] of problemPaths"
        v-slot:[`cell(${pointsPath})`]="data"
      >
        <div
          :key="`cell-${pointsPath}`"
          v-b-popover.hover.top.window="
            getProblemPopover(data.item, pointsPath, countedPath)
          "
          :class="get(data.item, countedPath) ? '' : 'text-muted'"
        >
          {{ get(data.item, pointsPath) }}
        </div>
      </template>
      <template v-slot:empty>
        V tejto kategórii nie sú obodované žiadne riešenia.
      </template>
    </b-table>
  </LoadingSpinner>
</template>

<script>
import {
  apiResults,
  LoadingSpinner,
  PositionChange,
  utils,
} from "frontend-common";
import get from "lodash.get";

export default {
  name: "ResultTable",
  components: {
    PositionChange,
    LoadingSpinner,
  },
  mixins: [apiResults],
  props: {
    roundId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      default: null,
    },
    tableId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      get,
      table: null,
      ready: false,
    };
  },
  computed: {
    headerProcessed() {
      if (!this.table) return [];

      this.table.data.header.forEach((field) => {
        field.sortable = false;
        field.variant = field.group ? "warning" : "";
        field.tooltip = field.group
          ? "Polročná úloha. Výsledný počet bodov bude " +
            "najväčší získaný za túto úlohu počas celej časti."
          : "";
      });
      return this.table.data.header;
    },
    itemsProcessed() {
      if (!this.table) return [];
      if (this.userId) {
        this.table.data.rows.forEach((row) => {
          if (row.enrollment.user.id === this.userId)
            row._rowVariant = "primary";
        });
      }
      return this.table.data.rows;
    },
    problemPaths() {
      return this.headerProcessed
        .filter((item) => item.type === "problem")
        .map((item) => [item.key, item.counted]);
    },
  },
  watch: {
    tableId: {
      handler: function (value) {
        this.apiResultTable(value)
          .then((response) => {
            this.table = response;
          })
          .finally(() => {
            this.ready = true;
            this.$emit("ready");
          });
      },
      immediate: true,
    },
  },
  methods: {
    shortSchoolName(school) {
      if (!school || school.length < 20) return school;
      return `${school.substring(0, 17)}...`;
    },
    getProblemPopover(problemData, pointsPath, countedPath) {
      return utils.problemPopover(
        get(problemData, pointsPath),
        get(problemData, countedPath),
      );
    },
  },
};
</script>

<style scoped></style>
