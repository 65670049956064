import { render, staticRenderFns } from "./Results.vue?vue&type=template&id=7f80f378&scoped=true&"
import script from "./Results.vue?vue&type=script&lang=js&"
export * from "./Results.vue?vue&type=script&lang=js&"
import style0 from "./Results.vue?vue&type=style&index=0&id=7f80f378&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f80f378",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BContainer, BCol, BRow, BJumbotron, BLink} from 'bootstrap-vue'
    installComponents(component, {BContainer, BCol, BRow, BJumbotron, BLink})
    

export default component.exports