import { render, staticRenderFns } from "./ResultTable.vue?vue&type=template&id=4484b6e4&scoped=true&"
import script from "./ResultTable.vue?vue&type=script&lang=js&"
export * from "./ResultTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4484b6e4",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BLink, BTable} from 'bootstrap-vue'
    installComponents(component, {BLink, BTable})
    


    import installDirectives from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installDirectives.js"
    import {VBPopover} from 'bootstrap-vue'
    installDirectives(component, {'b-popover': VBPopover})
    

export default component.exports