var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LoadingSpinner',{attrs:{"is-loaded":_vm.ready}},[(_vm.ready)?_c('b-table',{staticClass:"text-nowrap mt-3",attrs:{"hover":"","small":"","sort-icon-left":"","bordered":"","responsive":true,"show-empty":"","fields":_vm.headerProcessed,"items":_vm.itemsProcessed,"sort-by":_vm.headerProcessed[0].key,"sort-desc":false},scopedSlots:_vm._u([_vm._l((_vm.headerProcessed),function(field){return {key:("head(" + (field.key) + ")"),fn:function(data){return [(data.field.type === 'problem')?_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"top":true,"window":true}}],key:("header-" + (field.key)),class:("header-" + (field.type)),attrs:{"to":{
          name: 'round',
          params: { roundId: _vm.roundId },
          hash: ("#uloha-" + (data.label)),
        }}},[_vm._v(" "+_vm._s(data.label)+" ")]):_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:(field.tooltip),expression:"field.tooltip",modifiers:{"hover":true,"top":true,"window":true}}],key:("header-" + (field.key)),attrs:{"id":("header-" + (field.key))}},[_vm._v(" "+_vm._s(data.label)+" ")])]}}}),{key:"cell(change)",fn:function(data){return [_c('PositionChange',{attrs:{"change":data.item.change}})]}},{key:"cell(enrollment.school)",fn:function(data){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:(data.item.enrollment.school_name),expression:"data.item.enrollment.school_name",modifiers:{"hover":true,"top":true,"window":true}}]},[_vm._v(" "+_vm._s(_vm.shortSchoolName(data.item.enrollment.school))+" ")])]}},_vm._l((_vm.problemPaths),function(ref){
        var pointsPath = ref[0];
        var countedPath = ref[1];
return {key:("cell(" + pointsPath + ")"),fn:function(data){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top.window",value:(
          _vm.getProblemPopover(data.item, pointsPath, countedPath)
        ),expression:"\n          getProblemPopover(data.item, pointsPath, countedPath)\n        ",modifiers:{"hover":true,"top":true,"window":true}}],key:("cell-" + pointsPath),class:_vm.get(data.item, countedPath) ? '' : 'text-muted'},[_vm._v(" "+_vm._s(_vm.get(data.item, pointsPath))+" ")])]}}}),{key:"empty",fn:function(){return [_vm._v(" V tejto kategórii nie sú obodované žiadne riešenia. ")]},proxy:true}],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }